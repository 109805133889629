const newseLatterData = [
  {
    id: '1',
    icon: { parentBg: '#beaaf7', childBg: 'rgb(131 88 255) ', svg: 'icon-wallet' },

    title: ['ウォレットを設定'],
    text: 'ウォレットを設定したら、NFTマーケットプレイス (OpenSea) に接続します。',
  },
  {
    id: '2',
    icon: { parentBg: '#c4f2e3', childBg: 'rgb(16 185 129)', svg: 'icon-wallet' },

    title: ['コレクションを作成'],
    text: 'コレクションの作成と設定をクリックします。ソーシャルリンク、説明、プロフィールとバナー画像を追加し、二次販売手数料を設定します。',
  },
  {
    id: '3',
    icon: { parentBg: '#cddffb', childBg: 'rgb(66 138 248)', svg: 'icon-gallery' },
    title: ['NFTを追加'],
    text: '作品 (画像、ビデオ、オーディオ、または 3D アート) をアップロードし、タイトルと説明を追加し、プロパティや統計を使用してNFTをカスタマイズします。',
  },
  {
    id: '4',
    icon: { parentBg: '#ffd0d0', childBg: 'rgb(239 68 68)', svg: 'icon-list' },
    title: ['販売用にリスト'],
    text: 'オークション、定価出品、定価出品からお選びください。NFTの販売方法を選択できます。',
  },
]

export { newseLatterData }
