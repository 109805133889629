const hero_3_swiper_data = [
  {
    id: '0Palettes of Aotearoa - The Place of greenstone #01',
    image: '/images/hero/hero_slide_1.jpg',
    title: 'Palettes of Aotearoa - The Place of greenstone #01',
    name: 'LeeCookNZ',
    titleLink: '#',
    userLink: '#',
  },
  {
    id: '1Portrait Gallery',
    image: '/images/hero/hero_slide_2.jpg',
    title: 'Portrait Gallery',
    name: 'By 051_Hart',
    titleLink: '#',
    userLink: '#',
  },
  {
    id: '2Oceania \\\\ OVERSEER 017',
    image: '/images/hero/hero_slide_3.jpg',
    title: 'Oceania \\\\ OVERSEER 017',
    name: 'By THE OVERSEERS',
    titleLink: '#',
    userLink: '#',
  },
  {
    id: '3Shiba #368',
    image: '/images/hero/hero_slide_4.jpg',
    title: 'Shiba #368',
    name: 'Generous Shiba Club',
    titleLink: '#',
    userLink: '#',
  },
  {
    id: '4Palettes of Aotearoa - The Place of greenstone #01',
    image: '/images/hero/hero_slide_1.jpg',
    title: 'Palettes of Aotearoa - The Place of greenstone #01',
    name: 'LeeCookNZ',
    titleLink: '#',
    userLink: '#',
  },
  {
    id: '5Portrait Gallery',
    image: '/images/hero/hero_slide_2.jpg',
    title: 'Portrait Gallery',
    name: 'By 051_Hart',
    titleLink: '#',
    userLink: '#',
  },
]

const hero_3_swiper_thumbs_data = [
  {
    id: '0/images/hero/hero_slide_1_thumb.jpg',
    image: '/images/hero/hero_slide_1_thumb.jpg',
  },
  {
    id: '1/images/hero/hero_slide_2_thumb.jpg',
    image: '/images/hero/hero_slide_2_thumb.jpg',
  },
  {
    id: '2/images/hero/hero_slide_3_thumb.jpg',
    image: '/images/hero/hero_slide_3_thumb.jpg',
  },
  {
    id: '3/images/hero/hero_slide_4_thumb.jpg',
    image: '/images/hero/hero_slide_4_thumb.jpg',
  },
  {
    id: '4/images/hero/hero_slide_1_thumb.jpg',
    image: '/images/hero/hero_slide_1_thumb.jpg',
  },
  {
    id: '5/images/hero/hero_slide_4_thumb.jpg',
    image: '/images/hero/hero_slide_4_thumb.jpg',
  },
]

export { hero_3_swiper_data, hero_3_swiper_thumbs_data }
